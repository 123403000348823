export var globals = {
	host_url: "https://mralandschapslim.nl/",
	//host_url: "https://mra.multitudemo.nl/",
	//host_url: 'http://localhost/',
	search_results:null,
	project_name: "m_r_a",
	loggedIn: false,
	api_data: {},
	nodeList:null,
	smartZoom:false,
	minScale:0.3,
	lastTreeClicked:null,
	dontOpenModal: false,
	selectedId:null,
	consolify: false,
};

/*
if (process.env.NODE_ENV === "production") {
	globals.host = "https://mralandschapslim.nl/";
}
*/

export default globals;
