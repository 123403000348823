
import * as cheet from 'cheet.js'
import { EventBus } from './event_bus'
import globals from './globals'


var lv = {}

let control_main = {
    console_log(msg){
        lv.golog = false
        if(process.env.NODE_ENV !== 'production'){
            lv.golog = true
        }
        if(globals.consolify){
            lv.golog = true
        }
        if(lv.golog){
            console.log(msg)
        }
    },
    dom_fetcher(){
        console.log(document.getElementById('app').innerHTML)
    }
}

cheet('g l o b a l s', function () {
    console.log(globals)
})

cheet('d o m f e t c h', function () {
    control_main.dom_fetcher()
})
 
cheet('c o n s o l i f y', function () {
    if(localStorage.getItem('consolify')){
        console.log('got consolify, removing it')
        globals.consolify = false
        localStorage.removeItem('consolify')
    }else{
        console.log('havent got consolify, setting it')
        globals.consolify = true
        localStorage.setItem('consolify', true)
    }
})
  
cheet('l i v e u p d a t e', function () {
    EventBus.$emit('live_update')
    if(globals.live_update){
        globals.live_update = false
        console.log('DISBLED LIVE UPDATE')
    }else{
        globals.live_update = true
        console.log('ENABLED LIVE UPDATE')
    }
})
  
export default control_main
