<template>
	<div id="app" class="select-none">
		<router-view />
	</div>
</template>

<script>

	export default {
		name: "app",
		components: {
		},
		data() {
			return {};
		},
		mounted() {
		},
		methods: {},
	};
</script>

<style>
</style>
