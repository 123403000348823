import Vue from "vue"
import app from "./app.vue"
import router from "./router"

import api from '@/modules/api'

import "@/assets/styles/main.css"
import "@/assets/styles/tailwind.css"
import panZoom from 'vue-panzoom'

// install plugin
Vue.use(panZoom);

import { globals } from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

if (process.env.NODE_ENV === "production") {
	console.log(
		"%c MRA :: by multitude.nl ",
		"background: #000000; color: #fff; padding:3px; font-size:16px;"
	);
}

Vue.config.productionTip = false

function app_start() {
	new Vue({
		router,
		render: (h) => h(app),
	}).$mount("#app")
}

api.call({
  task: 'fetch_intro',
  event: 'fetch_intro_done',
  global: true,
})
EventBus.$on('fetch_intro_done', (e) => {
  dataLoaded(e)
})

function dataLoaded(d){
	console.log('===================== fetched intro data')
	console.log(d)
	if(d.data.loggedIn){
		globals.loggedIn = true
	}
}

EventBus.$on('fetch_intro_done', (e) => {
	app_start()
})


/*
app_start()
*/


