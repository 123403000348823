import Vue from "vue";
import VueRouter from "vue-router";
import { globals } from "@/modules/globals";
import { EventBus } from "@/modules/event_bus";

Vue.use(VueRouter);
const routes = [
	
	{
		path: "/account-activeren/:salt",
		name: "account_activeren",
		component: () =>
			import(/* webpackChunkName: "main" */ "@/components/AccountActivate.vue"),
	},
	{
		path: "/wachtwoord-vergeten",
		name: "wachtwoord_vergeten",
		component: () =>
			import(/* webpackChunkName: "main" */ "@/components/PasswordResetRequest.vue"),
	},
	{
		path: "/wachtwoord-herstellen/:salt",
		name: "wachtwoord_herstellen",
		component: () =>
			import(/* webpackChunkName: "main" */ "@/components/PasswordReset.vue"),
	},
	{
		path: "*",
		name: "main",
		component: () =>
			import(/* webpackChunkName: "main" */ "@/components/MainRoute.vue"),
	},

]

var baseRoute = "";

const router = new VueRouter({
	mode: "history",
	base: baseRoute,
	routes: routes
})


export default router;
